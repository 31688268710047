<template>
    <b-container class="all-news-page">
        <div class="section-first-news">
            <div class="box-first-news" v-if="getFirst">
                <a
                    class="thumbnail"
                    @click="
                        $router.push({
                            name: 'Detail',
                            params: {
                                locale: $i18n.locale,
                                type: getFirst.blog_slug,
                                id: getFirst.article_slug,
                            },
                        })
                    "
                >
                    <img :src="getFirst.article_thumbnail" alt="thumnail" />
                </a>
                <div class="content-news">
                    <div class="info-news">
                        <div class="date">
                            {{
                                getDateTime(
                                    getFirst.created_at,
                                    getFirst.article_lang,
                                )
                            }}
                        </div>
                        <router-link
                            :to="{
                                path: `/${$i18n.locale}/${getFirst.blog_slug}`,
                            }"
                        >
                            <div class="type-news">
                                {{ getFirst.article_blog_name }}
                            </div>
                        </router-link>
                    </div>
                    <router-link
                        :to="{
                            name: 'Detail',
                            params: {
                                locale: $i18n.locale,
                                type: getFirst.blog_slug,
                                id: getFirst.article_slug,
                            },
                        }"
                    >
                        <h3 class="title">{{ getFirst.article_title }}</h3>
                    </router-link>
                    <div
                        class="description"
                        v-html="getFirst.article_description"
                    ></div>
                </div>
            </div>
            <div
                class="text-center d-flex h-100 align-items-center justify-content-center"
                style="min-height: 300px; font-size: 25px; font-weight: 600"
                v-else
            >
                {{ $t('article.content') }}
            </div>
        </div>

        <div class="section-news">
            <b-row class="list-news">
                <b-col
                    lg="4"
                    sm="6"
                    cols="12"
                    v-for="news in getFromSecond"
                    :key="`box-news-${news.article_id}`"
                >
                    <div class="news-box">
                        <router-link
                            :to="{
                                name: 'Detail',
                                params: {
                                    locale: $i18n.locale,
                                    type: news.blog_slug,
                                    id: news.article_slug,
                                },
                            }"
                            class="thumbnail"
                        >
                            <img :src="news.article_thumbnail" alt="" />
                        </router-link>
                        <div class="content-news">
                            <div class="info-news">
                                <div class="date">
                                    {{
                                        getDateTime(
                                            news.created_at,
                                            news.article_lang,
                                        )
                                    }}
                                </div>
                                <router-link
                                    :to="{
                                        name: 'Detail',
                                        params: {
                                            locale: $i18n.locale,
                                            type: news.blog_slug,
                                            id: news.article_slug,
                                        },
                                    }"
                                >
                                    <div class="type-news">
                                        {{ news.article_blog_name }}
                                    </div>
                                </router-link>
                            </div>
                            <router-link
                                :to="{
                                    name: 'Detail',
                                    params: {
                                        locale: $i18n.locale,
                                        type: news.blog_slug,
                                        id: news.article_slug,
                                    },
                                }"
                            >
                                <h3 class="title">
                                    {{ news.article_title }}
                                </h3>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    class="d-flex justify-content-center align-items-center my-3"
                    v-if="
                        NewsAll.length >= 1 &&
                        NewsAll.total > 1 &&
                        newsAll.current <= NewsAll.total
                    "
                >
                    <b-button class="button-load">Load More</b-button>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            NewsAll: 'blog/NewsAll',
        }),
        getFirst() {
            if (this.NewsAll.list.length < 1) {
                return undefined;
            }
            return this.NewsAll.list[0];
        },
        getFromSecond() {
            if (this.NewsAll.list.length < 2) {
                return [];
            }
            return this.NewsAll.list.slice(1, -1);
        },
    },
    watch: {
        '$i18n.locale': {
            handler() {
                this.$store.dispatch({ type: 'blog/req_getNewsAll' });
            },
        },
    },
};
</script>

<style lang="scss">
.all-news-page {
    padding: 50px 15px;
    color: #383e3a;
}
.box-first-news {
    a {
        color: #16141a !important;
    }
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;

    .thumbnail {
        flex: 1 1 60%;
        display: flex;
        justify-content: flex-start;
        img {
            width: 100%;
            height: calc(697px * 0.46);
            max-width: 697px;
            object-fit: cover;
            object-position: center;
            border-radius: 15px;
            box-shadow: 2px 3px 15px 2px rgba(0, 0, 0, 0.2);
        }
    }
    .content-news {
        flex: 1 1 40%;
        padding-left: 20px;
        .info-news {
            display: flex;
            .date {
                margin-right: 16px;
                color: #16141a;
                font-size: 13px;
                font-weight: 600;
                text-decoration: none;
                text-transform: uppercase;
            }
            .type-news {
                color: #7d8499;
                font-size: 13px;
                text-decoration: none;
            }
        }
        h3.title {
            color: #16141a;
            text-decoration: none;
            margin: 20px 0;
            &:hover {
                text-decoration: underline !important;
            }
        }
        .description {
            display: block;
            font-size: 17px;
            line-height: 26px;
            letter-spacing: -0.4px;
            margin: 0;
            padding-top: 8px;
            color: #16141a;
        }
    }
    @media (max-width: 991px) {
        flex-direction: column;
        .thumbnail {
            flex: unset;
            width: 100%;
        }
        .info-news {
            margin-top: 20px;
        }
        .content-news {
            flex: unset;
            width: 100%;
            h3.title {
                margin: 5px 0 0;
            }
        }
    }
    @media (max-width: 575px) {
        .thumbnail {
            width: 100%;
            overflow: hidden;
            margin: 0;
            padding-top: 40.25%;
            position: relative;
            border-radius: 10px;
            max-height: 300px;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                border-radius: 10px;
                object-fit: cover;
                max-height: 300px;
            }
        }
    }
    @media (max-width: 425px) {
        .thumbnail {
            padding-top: 56.25%;
        }
    }
}
.section-news {
    margin-top: 50px;
    .news-box {
        a {
            color: #16141a !important;
        }
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        text-decoration: none !important;
        margin-bottom: 30px;
        cursor: pointer;
        .thumbnail {
            width: 100%;
            display: flex;
            justify-content: center;
            border-radius: 8px;
            @media (min-width: 578px) {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
            }
            img {
                width: 100%;
                object-fit: cover;
                object-position: center;
                transition: transform 250ms ease, box-shadow 250ms ease;
                border-radius: 10px;
                height: 180px;
            }
        }
        .content-news {
            flex: 1 1 auto;
            padding: 0 10px 5px;
            .info-news {
                display: flex;
                margin: 15px 0 5px;
                .date {
                    margin-right: 16px;
                    color: #16141a;
                    font-size: 13px;
                    font-weight: 600;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                .type-news {
                    color: #7d8499;
                    font-size: 13px;
                    text-decoration: none;
                }
            }
            h3.title {
                color: #16141a;
                text-decoration: none;
                margin: 0;
                font-size: 22px;
                line-height: 28px;
                font-weight: 500;
                &:hover {
                    text-decoration: underline !important;
                }
            }
            .description {
                display: block;
                font-size: 17px;
                line-height: 26px;
                letter-spacing: -0.4px;
                margin: 0;
                padding-top: 8px;
                color: #16141a;
            }
        }
        @media (max-width: 575px) {
            .thumbnail {
                width: 100%;
                overflow: hidden;
                margin: 0;
                padding-top: 40.25%;
                position: relative;
                border-radius: 10px;
                max-height: 300px;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    border-radius: 10px;
                    object-fit: cover;
                    max-height: 300px;
                }
            }
        }
        @media (max-width: 425px) {
            .thumbnail {
                padding-top: 56.25%;
            }
        }
    }
    .button-load {
        background: linear-gradient(90deg, #00ffa9, #00b1ff);
        width: 200px;
        height: 50px;
        padding: 0;
        color: #000;
        font-family: Helvetica;
        font-size: 1.1em;
        font-weight: 900;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px auto;
    }
}
</style>
