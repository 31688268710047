<template>
    <b-container fluid class="p-0">
        <div class="banner">
            <img src="~@/assets/images/background/banner.png" alt="" />
            <b-container>
                <div class="page-title">{{ $t('technology.title') }}</div>
                <div class="page-subtile">
                    {{ $t('technology.content') }}
                </div>
            </b-container>
        </div>
        <b-container>
            <div class="list-news-2">
                <div
                    class="text-center d-flex h-100 align-items-center justify-content-center"
                    style="min-height: 300px; font-size: 25px; font-weight: 600"
                    v-if="list.length <= 0"
                >
                    {{ $t('technology.not_found') }}
                </div>
                <router-link
                    :to="{
                        name: 'Detail',
                        params: {
                            type: blog.blog_slug,
                            id: blog.article_slug,
                            locale: $i18n.locale,
                        },
                    }"
                    class="news-box-2"
                    v-for="(blog, index) in list"
                    :key="`list-2-34-${index}`"
                >
                    <div class="info">
                        <span class="date">
                            {{
                                getDateTime(blog.created_at, blog.article_lang)
                            }}
                        </span>
                        <h3>{{ blog.article_title }}</h3>
                    </div>
                    <div class="thumbnail">
                        <img :src="blog.article_thumbnail" alt="" />
                    </div>
                </router-link>
                <infinite-loading
                    v-if="list.length && page < totalPages"
                    spinner="spiral"
                    @infinite="infiniteScroll"
                ></infinite-loading>
            </div>
        </b-container>
    </b-container>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

const Axios = require('@/store/axios.config').default;

export default {
    components: {
        InfiniteLoading,
    },
    data() {
        return {
            page: 1,
            list: [],
            totalPages: 1,
            list2: 10,
        };
    },

    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await Axios.get('/api/v1/article/blog', {
                params: {
                    slug: this.$route.path
                        .replace(/\//g, '')
                        .replace(this.$i18n.locale, ''),
                },
            });
            if (response.data) {
                const { data } = response;
                this.list = data.data;
                this.page = data.current_page;
                this.totalPages = data.last_page;
            }
        },
        infiniteScroll($state) {
            this.page += 1;
            if (this.page > this.totalPages) {
                return;
            }
            setTimeout(() => {
                // eslint-disable-next-line no-plusplus
                Axios.get('/api/v1/article/blog', {
                    params: {
                        slug: this.$route.path
                            .replace(/\//g, '')
                            .replace(this.$i18n.locale, ''),
                        page: this.page,
                    },
                })
                    .then((response) => {
                        if (response.data && response.data.data.length > 1) {
                            const { data } = response;
                            data.data.forEach((item) => this.list.push(item));
                            this.page = data.current_page;
                            this.totalPages = data.last_page;
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 500);
        },
    },
    watch: {
        '$i18n.locale': {
            handler() {
                this.fetchData();
            },
        },
    },
};
</script>
<style lang="scss">
.banner {
    width: 100%;
    height: 300px;
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(94deg, #00172afa 0%, #00000b45 100%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
    .page-title,
    .page-subtile {
        position: relative;
        z-index: 10;
        color: #fff;
    }
    .page-title {
        margin: 0 0 12px;
        font-size: 34px;
        letter-spacing: 0.4px;
        line-height: 42px;
        font-weight: 500;
        @media (min-width: 576px) {
            font-size: 40px;
            line-height: 48px;
        }
    }
    .page-subtile {
        margin: 0;
        font-size: 20px;
        letter-spacing: 0.3px;
        line-height: 28px;
    }
}
.list-news-2 {
    padding: 20px 0;
    .news-box-2 {
        display: flex;
        color: #16141a;
        width: 100%;
        border-bottom: rgba(92, 92, 110, 0.2) 1px solid;
        transition: background-color 250ms ease;
        justify-content: space-between;
        align-items: center;
        text-decoration: none !important;
        border-radius: 5px;
        &:hover {
            background-color: #ccc;
            padding: 8px 0;
            .info {
                transform: translate(10px, 0);
            }
            .thumbnail {
                transform: translate(-10px, 0);
                padding: 0;
            }
        }
        .info {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            transition: transform 250ms ease;
            align-items: center;
            padding: 18px 0;
            .date {
                font-size: 15px;
                line-height: 24px;
            }
            h3 {
                margin-bottom: 0;
            }
            @media (min-width: 750px) {
                .date,
                h3 {
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: 0.3px;
                }
            }
            @media (min-width: 1024px) {
                align-items: center;
                flex-direction: row;

                .date {
                    flex: 0 0 fit-content;
                    padding-right: 20px;
                    min-width: 150px;
                }
            }
        }
        .thumbnail {
            transition: transform 250ms ease;
            padding: 10px 0;
            img {
                width: 72px;
                height: 72px;
                border-radius: 6px;
                @media (min-width: 578px) {
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
                }
                object-fit: cover;
                margin-left: 24px;
                @media (min-width: 568px) {
                    width: 108px;
                    height: 60px;
                    margin-left: 48px;
                }
            }
        }
    }
}
</style>
